import React from "react";
import Table from "components/shared/Table";
import { VR_STORES } from "src/utils/market/vr-stores";
import * as classes from "../rankings.module.less";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "VR Store",
      dataIndex: "short_name",
      render: (short_name, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          <div className={classes.image}>{element.image}</div>
          {element.website ? (
            <a
              href={element.website}
              rel="noopener noreferrer"
              target="_blank"
              title={element.website}
            >
              <span>{short_name}</span>
            </a>
          ) : (
            <span>{short_name}</span>
          )}
        </div>
      ),
    }
  ];

  return (
    <Table
      responsive
      dataSource={VR_STORES}
      columns={columns}
      rowKey="index"
      expandedRowRender={(record) => (
        <div>
          {record.details.map((item) => (
            <p className={classes.detailsP}>{item}</p>
          ))}
        </div>
      )}
    />
  );
};
