import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import VrStores from "components/market/VrStores";
import MarketRelatedContent from "components/market/MarketRelatedContent";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/app-store.svg";
import CTA from "components/shared/CTA";
import { Link } from "gatsby";

export default () => (
  <>
    <Meta url="/market/vr-stores/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="VR Store"
      black="Index"
      text={
        <p>
          {" "}
          The AppInChina VR App Store Index is the market-leading index of China's major VR App Stores. We update our index on a quarterly basis using the most accurate data sources available.
          <br /> <br />
          If you are interested in launching your software for VR Devices, don't hesitate to reach out to us: {" "}
          <Link to="/get-started/">Get Started</Link>
          <br /> <br />
          <b>Last Updated: September 2023</b>
        </p>
      }
    />
    <br />
    <br />
    <Layout>
      <MarketRelatedContent>
        <VrStores />
      </MarketRelatedContent>
      <br />
      <br />
    </Layout>
      <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
