import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  layout: "fullWidth",
  placeholder: "blurred",
  alt: "App Store Icon"
};

export const VR_STORES = [
  {'short_name': 'PICO App Store',
  'image': <StaticImage src="../../images/stores/PICO.jpeg" {...imgProps} />,
'website': 'https://www.picoxr.com/cn/',
'details': ['Chinese name: PICO',
 'Parent company: BYTEDANCE',
 "Description: Founded in March 2015, PICO is a leading VR company with independent innovation and R&D capabilities, focusing on VR all-in-one technology. PICO is commited to building an integrated XR platform that inspires the community and empowers developers, creators, and businessess. PICO's devices are the best selling VR devices in China since 2021, with over a million devices sold since Q3 2022, making PICO the unanimous industry leader of VR Headsets in China."]},
{'short_name': 'Huawei AppGallery China',
'image': <StaticImage src="../../images/stores/Huawei_AppGallery.svg" {...imgProps} />,
'website': 'https://appgallery.huawei.com/Featured',
'details': ['Chinese name: 华为应用市场',
 'Parent company: HUAWEI',
 'Description: With the growing popularity of VR Apps in China, Huawei AppGallery has included VR-Ready Android (Harmony OS) Apps on its App Store. Huawei AppGallery is already the largest, most popular App Store in China, with the addition of VR-Ready Apps, Huawei Devices have the capability to connect with third-party VR Headsets to achieve Android VR cross platform functionalities. Huawei is also deeply invested in ecosystem development, creating their own AR Glasses in 2022.']},
{'short_name': 'Tencent MyApp',
'image': <StaticImage src="../../images/stores/tencent.png" {...imgProps} />,
'website': 'https://sj.qq.com/',
'details': ['Chinese name: 应用宝',
 'Parent company: TENCENT',
 'Description: The largest and most popular universal Android App Store in China that boasts around 100 million MAU is now including various VR-Ready Apps that are optimized and compatible to whichever Android Device is on hand.']},
{'short_name': 'Xiaomi App Store',
'image': <StaticImage src="../../images/stores/xiaomi.png" {...imgProps} />,
'website': 'https://app.mi.com/',
'details': ['Chinese name: 小米应用商店',
 'Parent company: XIAOMI',
 "Description: Similar to Huawei, Xiaomi has been striving to catch up with Huawei's ecosystem building. Xiaomi's devices have proven to be the most compatible to any third-party devices, making Xiaomi's Devices the most desirable when looking to pair with a VR-Headset. Although Xiaomi's VR Apps are limited, the sheer number of Xiaomi smart devices that could run VR will land Xiaomi App Store on the 4th spot."]},
{'short_name': 'Baidu Mobile Assistant',
'image': <StaticImage src="../../images/stores/baidu.png" {...imgProps} />,
'website': 'https://shouji.baidu.com/',
'details': ['Chinese name: 百度手机助手',
 'Parent company: BAIDU',
 "Description: Similar to Tencent, the Baidu Mobile Assistant is the second largest universal Android App Store in China. A company with a long standing success in China especially in regard to search engines, Baidu has evolved into a multi-portfolio company that has cutting edge technology in terms of AI and VR software application. Baidu's VR Solutions have been widely used by PICO, ICBC, Microsoft, iQiyi and many other industry giants."]},
{'short_name': 'iQiYi VR',
'image': <StaticImage src="../../images/stores/iqiyi.png" {...imgProps} />,
'website': 'https://www.iqiyismart.com/',
'details': ['Chinese name: 爱奇艺',
 'Parent company: iQiYi',
 "Description: One of the two largest streaming platforms in China, iQiYi is now much more than a content streaming platform. iQiYi has dabbled in many different sectors, but its VR hardwares and softwares truly stands out. iQiYi's VR Headset has cemented its place as the best VR Headset to watch Movies and TV Shows as it focuses primarily to bring cinema and IMAX level viewing experience to every househould. However, on the gaming side, the number of VR Game is not at the level of PICO, with only indie games availble to play."]}].map((it, index) => ({ ...it, index: index + 1 }));
